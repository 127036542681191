import { Seo } from "gatsby-plugin-wpgraphql-seo";
import { useSinglePageByDatabaseId } from "../../queries/wpPage/useSinglePageByDatabaseId";
import RVODefaultTemplate from "../04-templates/RVODefaultTemplate/RVODefaultTemplate";

export const ImpressumPage = (): JSX.Element => {
  const impressumPage = useSinglePageByDatabaseId(169);

  return (
    <>
      <Seo post={impressumPage} />

      <RVODefaultTemplate page={impressumPage} />
    </>
  );
};

export default ImpressumPage;
